import { json, type MetaFunction } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { Button } from '#app/components/ui/button'
import {
	Tooltip,
	TooltipProvider,
	TooltipTrigger,
} from '#app/components/ui/tooltip.tsx'
import { getGrants } from '#app/utils/grants.server.ts'
import { cn } from '#app/utils/misc.tsx'

export const meta: MetaFunction = () => [{ title: 'DoGood Grants' }]

export const loader = async () => {
	const grants = await getGrants({ limit: 10 })

	return json({ grants })
}

export default function Index() {
	const { grants } = useLoaderData<typeof loader>()

	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center px-4 py-16 xl:grid-cols-2 xl:gap-24">
				<div className="flex max-w-md flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
					<img src="/img/DoGood-logo-Small-1.jpg" alt="logo" className="w-24" />
					<h1
						data-heading
						className="text-foreground mt-8 animate-slide-top text-4xl font-medium [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						Grant Portal
					</h1>
					<p
						data-paragraph
						className="text-muted-foreground mt-6 animate-slide-top text-xl/7 [animation-fill-mode:backwards] [animation-delay:0.8s] xl:mt-8 xl:animate-slide-left xl:text-xl/6 xl:leading-10 xl:[animation-fill-mode:backwards] xl:[animation-delay:1s]"
					>
						Get your project funded.
					</p>
					<Button
						variant={'primary'}
						className="mt-2 animate-slide-top [animation-fill-mode:backwards] [animation-delay:0.8s]"
					>
						<Link className="text-xl text-white" to="/grants">
							Search Grants
						</Link>
					</Button>
				</div>
				<ul className="mt-16 flex max-w-3xl flex-wrap justify-center gap-2 sm:gap-8">
					<TooltipProvider>
						{grants.map((grant, i) => (
							<li
								key={grant.id}
								className={cn(
									// columnClasses[logo.column],
									// rowClasses[logo.row],
									'animate-roll-reveal [animation-fill-mode:backwards]',
									'rounded-sm bg-brand-cyan/30 p-2 transition hover:-rotate-2',
								)}
								style={{ animationDelay: `${i * 0.07}s` }}
							>
								<Tooltip>
									<TooltipTrigger asChild>
										<Link
											to={`/grants/${grant.id}`}
											className="rounded-2xl text-center transition hover:-rotate-6"
										>
											{grant.name}
										</Link>
									</TooltipTrigger>
								</Tooltip>
							</li>
						))}
					</TooltipProvider>
				</ul>
			</div>
		</main>
	)
}
